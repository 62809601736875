@import "../../_globalColor";

.skills-main-div {
  display: flex;
  flex-direction: row-reverse; /* Ensures image on the right, text on the left */
  width: 100%;
  overflow: hidden;
  justify-content: space-between; /* Spreads the image and text apart */
  align-items: center; /* Aligns the image and text vertically */
}

.skills-text-div {
  margin-right: 50px;
  margin-left: 50px;
}

.skills-main-div > * {
  flex: 1;
  margin-bottom: 30px;
}

.skills-image-div {
  display: flex;
  justify-content: flex-end;
}

.skills-image-div > img {
  max-width: 100%;
  height: auto;
}

.skills-heading {
  font-size: 56px;
  font-weight: 400;
}

.subTitle {
  color: $subTitle;
}

/* Media Query */
@media (max-width: 1380px) {
  .skills-heading {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .skills-main-div {
    flex-direction: column; /* Stack items vertically on small screens */
  }
  .skills-text-div {
    margin-left: 0;
    margin-right: 0;
    margin: 20px;
  }
  .skills-heading {
    font-size: 30px;
    text-align: center;
  }
  .skills-image-div {
    order: 2;
    justify-content: center;
  }
  .skills-image-div > img {
    max-width: 70%; /* Adjust the size for smaller screens */
  }
}
